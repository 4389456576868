<template>
  <master-layout>

    <ion-card>
      <ion-card-content>
        <div class="illustration-container">
          <img src="assets/images/illustration-rating.svg" class="Illustration"/>
        </div>
        <!-- <h1>Üdvözlünk az<br/>Országos Bortúra<br/>alkalmazásában!</h1> -->
        <h1>Gratulálunk, sikerült a regisztrációd.</h1>
        <p class="pCenter marginBottom">
          Lépj vissza az Országos Bortúra alkalmazásba és kezdd el a borélmények gyűjtéset!
        </p>
      </ion-card-content>
    </ion-card>
    <div class="bottomSpacer"></div>

  </master-layout>
</template>

<script>
import {
  IonCard, IonCardContent
} from '@ionic/vue'

export default {
  components: {
    IonCard, IonCardContent
  }
}
</script>

<style scoped>
.marginBottom{
  margin-bottom: 20px;
}
.illustration-container{
  width: 50%;
  margin: 40px auto 20px auto;
}
h1{
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: 33px;
}
</style>
